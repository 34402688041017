const actionTypes = {
  ADDITIONAL_ROWS_DATA_LOADED: "ADDITIONAL_ROWS_DATA_LOADED",
  ADD_ROWS: "ADD_ROWS",
  ALL_ROWS_DATA_LOADED: "ALL_ROWS_DATA_LOADED",
  ALL_ROWS_DATA_LOAD_ERROR: "ALL_ROWS_DATA_LOAD_ERROR",
  ALL_ROWS_LOADING_DATA: "ALL_ROWS_LOADING_DATA",
  APPLY_FILTERS_AND_SORTING: "APPLY_FILTERS_AND_SORTING",
  CELL_ROLLBACK_VALUE: "CELL_ROLLBACK_VALUE",
  CELL_SAVED_SUCCESSFULLY: "CELL_SAVED_SUCCESSFULLY",
  CELL_SET_VALUE: "CELL_SET_VALUE",
  CLEAN_UP: "CLEAN_UP",
  COLUMNS_DATA_LOADED: "COLUMNS_DATA_LOADED",
  COLUMNS_DATA_LOAD_ERROR: "COLUMNS_DATA_LOAD_ERROR",
  COLUMNS_LOADING_DATA: "COLUMNS_LOADING_DATA",
  COLUMN_EDIT: "COLUMN_EDIT",
  COLUMN_EDIT_ERROR: "COLUMN_EDIT_ERROR",
  COLUMN_EDIT_SUCCESS: "COLUMN_EDIT_SUCCESS",
  DELETE_ROW: "DELETE_ROW",
  DUPLICATE_ROW: "DUPLICATE_ROW",
  GENERATED_DISPLAY_VALUES: "GENERATE_DISPLAY_VALUES",
  HIDE_ALL_COLUMNS: "HIDE_ALL_COLUMNS",
  REMOVE_CELL_ANNOTATION: "REMOVE_CELL_ANNOTATION",
  ROW_CREATE: "ROW_CREATE",
  ROW_CREATE_ERROR: "ROW_CREATE_ERROR",
  ROW_CREATE_SUCCESS: "ROW_CREATE_SUCCESS",
  SET_ALL_ROWS_FINAL: "SET_ALL_ROWS_FINAL",
  SET_ANNOTATION_ERROR: "SET_ANNOTATION_ERROR",
  SET_CELL_ANNOTATION: "SET_CELL_ANNOTATION",
  SET_COLUMNS_VISIBLE: "SET_COLUMNS_VISIBLE",
  SET_CURRENT_LANGUAGE: "SET_CURRENT_LANGUAGE",
  SET_CURRENT_TABLE: "SET_CURRENT_TABLE",
  SET_DISPLAY_VALUE_WORKER: "SET_DISPLAY_VALUE_WORKER",
  SET_FILTERS_AND_SORTING: "SET_FILTERS_AND_SORTING",
  SET_ANNOTATION_HIGHLIGHT: "SET_ANNOTATION_HIGHLIGHT",
  SET_ROW_ANNOTATION: "SET_ROW_ANNOTATION",
  SET_SEARCH_OVERLAY: "SET_SEARCH_OVERLAY",
  SET_STATUS_INFO: "SET_STATUS_INFO",
  SET_USER_AUTHENTICATED: "SET_USER_AUTHENTICATED",
  START_GENERATING_DISPLAY_VALUES: "START_GENERATING_DISPLAY_VALUES",
  TABLE_DATA_LOADED: "TABLE_DATA_LOADED",
  TABLE_DATA_LOAD_ERROR: "TABLE_DATA_LOAD_ERROR",
  TABLE_NAME_EDIT: "TABLE_NAME_EDIT",
  TABLE_NAME_EDIT_SUCCESS: "TABLE_NAME_EDIT_SUCCESS",
  TOGGLE_COLUMN_VISIBILITY: "TOGGLE_COLUMN_VISIBILITY",
  TABLE_LOADING_DATA: "TABLE_LOADING_DATA",
  TABLE_NAME_EDIT_ERROR: "TABLE_NAME_EDIT_ERROR",
  SET_COLUMN_ORDERING: "SET_COLUMN_ORDERING",
  SET_STATE: "SET_STATE",
  SET_GLOBAL_SETTINGS: "SET_GLOBAL_SETTINGS",

  tableView: {
    TOGGLE_CELL_SELECTION: "TOGGLE_CELL_SELECTION",
    TOGGLE_CELL_EDITING: "TOGGLE_CELL_EDITING",
    SET_PREVENT_CELL_DESELECTION: "SET_PREVENT_CELL_SELECTION",
    COPY_CELL_VALUE_TO_CLIPBOARD: "COPY_CELL_VALUE_TO_CLIPBOARD",
    PASTE_CELL_VALUE: "PASTE_CELL_VALUE",
    SELECT_NEXT_CELL: "SELECT_NEXT_CELL",
    TOGGLE_ROW_EXPAND: "TOGGLE_ROW_EXPAND",
    CREATE_ROW_OR_SELECT_NEXT_CELL: "CREATE_ROW_OR_SELECT_NEXT_CELL",
    SHOW_ROW_CONTEXT_MENU: "SHOW_ROW_CONTEXT_MENU",
    CLOSE_ROW_CONTEXT_MENU: "CLOSE_ROW_CONTEXT_MENU",
    TOGGLE_EXPANDED_ROW: "TOGGLE_EXPANDED_ROW",
    RERENDER_TABLE: "RERENDER_TABLE"
  },

  overlays: {
    OPEN_OVERLAY: "OPEN_OVERLAY",
    CLOSE_OVERLAY: "CLOSE_OVERLAY",
    REMOVE_OVERLAY: "REMOVE_OVERLAY",
    SET_OVERLAY_STATE: "SET_OVERLAY_STATE",
    SHOW_TOAST: "SHOW_TOAST",
    HIDE_TOAST: "HIDE_TOAST"
  },

  media: {
    MEDIA_FOLDER_GET: "MEDIA_FOLDER_GET",
    MEDIA_FOLDER_GET_SUCCESS: "MEDIA_FOLDER_GET_SUCCESS",
    MEDIA_FOLDER_GET_ERROR: "MEDIA_FOLDER_GET_ERROR",
    MEDIA_FOLDER_CREATE: "MEDIA_FOLDER_CREATE",
    MEDIA_FOLDER_CREATE_SUCCESS: "MEDIA_FOLDER_CREATE_SUCCESS",
    MEDIA_FOLDER_CREATE_ERROR: "MEDIA_FOLDER_CREATE_ERROR",
    MEDIA_FOLDER_EDIT: "MEDIA_FOLDER_EDIT",
    MEDIA_FOLDER_EDIT_SUCCESS: "MEDIA_FOLDER_EDIT_SUCCESS",
    MEDIA_FOLDER_EDIT_ERROR: "MEDIA_FOLDER_EDIT_ERROR",
    MEDIA_FOLDER_DELETE: "MEDIA_FOLDER_DELETE",
    MEDIA_FOLDER_DELETE_SUCCESS: "MEDIA_FOLDER_DELETE_SUCCESS",
    MEDIA_FOLDER_DELETE_ERROR: "MEDIA_FOLDER_DELETE_ERROR",

    MEDIA_FILE_GET: "MEDIA_FILE_GET",
    MEDIA_FILE_GET_SUCCESS: "MEDIA_FILE_GET_SUCCESS",
    MEDIA_FILE_GET_ERROR: "MEDIA_FILE_GET_ERROR",
    MEDIA_FILE_EDIT: "MEDIA_FILE_EDIT",
    MEDIA_FILE_EDIT_SUCCESS: "MEDIA_FILE_EDIT_SUCCESS",
    MEDIA_FILE_EDIT_ERROR: "MEDIA_FILE_EDIT_ERROR",
    MEDIA_FILE_DELETE: "MEDIA_FILE_DELETE",
    MEDIA_FILE_DELETE_SUCCESS: "MEDIA_FILE_DELETE_SUCCESS",
    MEDIA_FILE_DELETE_ERROR: "MEDIA_FILE_DELETE_ERROR"
  },

  frontendServices: {
    QUERY_FRONTEND_SERVICES: "QUERY_FRONTEND_SERVICES",
    FRONTEND_SERVICES_LOADED: "FRONTEND_SERVICES_LOADED",
    QUERY_FRONTEND_SERVICES_FAILED: "QUERY_FRONTEND_SERVICES_FAILED"
  },

  multiSelect: {
    CLEAR_MULTISELECT: "CLEAR_MULTISELECT",
    TOGGLE_MULTISELECT_AREA: "TOGGLE_MULTISELECT_AREA",
    TOGGLE_MULTISELECT_CELL: "TOGGLE_MULTISELECT_CELL"
  }
};

export default actionTypes;
